import React from "react";
import { FaArrowRight, FaPlay } from "react-icons/fa";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { FaAnglesLeft } from "react-icons/fa6";
import { AiOutlineDoubleRight } from "react-icons/ai";

export default function Banner() {
  return (
    <section className="w-[100vw] text-white flex items-center justify-center overflow-x-hidden bg-[#252525]">
      <div className="w-[100%] max-w-[1400px] flex items-center max-md:px-[3%] md:pl-[2rem] py-[4rem]">
        <div className="w-[100%] lg:flex  max-lg:flex max-lg:flex-col gap-5 h-[100%]">
          <div className="lg:w-[60%] gap-4 md:px-[3rem] flex flex-col max-md: p-[2rem] hero-content">
            <h1 className="md:text-[68px] max-md:text-4xl md:leading-[75px] uppercase font-bold tracking-wider">
            Explore Our Exciting Upcoming Projects Here
            </h1>
            <p className="text-xl font-semibold mt-3 tracking-wider">
            Our upcoming projects will bring vibrant public spaces, modern infrastructure, and innovative solutions to elevate community living like never before.
            </p>
            <div className="flex gap-5 mt-8 items-center ">
              <a
                className="theme-btn flex gap-2 bg-white hover:text-white text-black wow fadeInUp"
                data-wow-delay=".3s"
                href="/about"
                style={{ visibility: "visible" }}
              >
               Enquire Now
                <FaArrowRight />
              </a>
              {/* <div className="flex items-center gap-3">
                <div className="w-[3rem] h-[3rem] border-white border-[2px] rounded-full flex items-center justify-center">
                  <FaPlay />
                </div>
                <span>play reel</span>
              </div> */}
            </div>
          </div>
          <div className="lg:w-[45%] max-lg:hidden flex flex-col gap-5 relative h-[100%]">
            <div className="flex gap-10 absolute bottom-[-10%] items-center">
              <div className=" flex items-center gap-1 custom-prev"><FaAnglesLeft />Previous</div>
              <div className="flex items-center gap-1 custom-next">Next<AiOutlineDoubleRight /></div>
            </div>
            <div className="w-[96%] flex overflow-hidden">
              <Swiper
              navigation={{
                prevEl: ".custom-prev", // Target custom classes for navigation
                nextEl: ".custom-next",
              }}
                spaceBetween={30} // Space between slides
                slidesPerView={1.4} // Show one slide at a time
                loop={true} // Enable looping
                speed={1000} // Slow transition speed in ms
                autoplay={{
                  delay: 3000, // Delay between transitions in ms
                  disableOnInteraction: false, // Continue autoplay even after interaction
                }}
                pagination={{ clickable: true }} // Show pagination dots
              
                modules={[Autoplay, Navigation, Pagination]}
                className="w-[100%] h-[33rem] flex "
              >
                <SwiperSlide>
                  <img
                    src={require("../AR/BANNER SLIDERS/BANNER 1.jpg")}
                    className="w-[100%] h-[100%] object-cover"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={require("../AR/BANNER SLIDERS/BANNER 2.jpg")}
                    className="w-[100%] h-[100%] object-cover"
                    alt=""
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={require("../../New/24.jpeg")}
                    className="w-[100%] h-[100%] object-cover"
                    alt=""
                  />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
