import React from "react";
import { FaArrowRight } from "react-icons/fa";

export default function Connect() {
  return (
    <section className="w-[100vw] overflow-hidden flex items-center justify-center ">
      <div className="max-w-[1400px] w-[100%] max-lg:px-[3%] lg:px-[7%] py-[4rem] items-center justify-center">
        <div className="w-[100%] lg:flex max-lg:flex max-lg:flex-col max-lg:gap-10 lg:justify-between px-[3rem] py-[7rem] bg1 relative overflow-hidden ">
          <h1 className="text-4xl font-bold text-black">
            Looking For Best Partner
            <br />
            For Your <span className="text-[#ff473a]">Next Construction</span>
            <br />
            Works?
          </h1>
          <div style={{zIndex:2}} className="absolute max-lg:hidden left-[50%] bottom-[-0rem]">
          <img src={require('../../Assets/cta-shape.webp')} alt="" />
            <div style={{zIndex:-0}} className="absolute max-lg:hidden top-0 ">
               
                <img style={{zIndex:9}} src={require('../../Assets/cta-img.webp')} alt="conect" className=""/>

            </div>
          </div>
          <a
                className="theme-btn flex gap-2 bg-white h-fit hover:text-white text-black wow fadeInUp"
                data-wow-delay=".3s"
                href="/service"
                style={{ visibility: "visible" }}
              >
                               Explore More service
                <FaArrowRight />
              </a>
        </div>
      </div>
    </section>
  );
}
