
import React from 'react'
import { FaArrowLeft, FaArrowRight} from 'react-icons/fa'
import Member from '../UI/Member'

export default function Team() {
  
  return (
    <section className="w-[100vw] flex items-center justify-center overflow-hidden">
    <div className="w-[100%] max-w-[1400px] bg3 flex-col flex max-md:px-[3%] md:px-[7%] pb-[3rem] max-md:pt-[3rem] md:pt-[7rem] justify-center items-center">
      <div className="w-[100%] items-center justify-center flex flex-col gap-5">
        <div className="flex w-[100%] items-center justify-center gap-3 text-[#ff523c]">
          <FaArrowLeft className="text-xl" />{" "}
          <h1 className="text-md font-semibold uppercase tracking-wider">
          Our expert team
          </h1>
          <FaArrowRight className="text-xl" />
        </div>
        <h1 className="md:text-5xl max-md:text-3xl  font-bold text-center">OUR AFFILIATIONS</h1>
      </div>
      <div className='w-[100%] mt-10 grid max-lg:grid-cols-1 lg:grid-cols-3 max-lg:gap-4 lg:gap-10'>
    
       <Member name='Appanna Rangappa Roogi' position='Member of Rotary Club'/>
       <Member name='Shrikant Appanna Roogi' position='Member of Lions Club'/>
       <Member name=' Tara Appanna Roog' position='Partner with State Government'/>
     
       
      </div>
    </div>
    </section>
  )
}
