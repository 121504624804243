import React, { useState } from 'react'
import { FaCheckCircle } from 'react-icons/fa'
import data from '../Constant/Projects'
import { useParams } from 'react-router-dom'
export default function Details() {
    const [load,setLoad]=useState(false);
    const [p,setP]=useState();
   const {id}= useParams()
   console.log(id)
   const project = data.find((project) => project.id === Number(id))
  return (
    <section className='w-[100vw] flex items-center overflow-hidden justify-center'>
      <div className="max-w-[1400px] w-[100%] px-[8%] pt-[6rem] pb-[3rem]">
        {project && <div className='w-[100%] flex flex-col gap-5'>
            <img src={project.img} alt="project"  className='w-[100%]'/>
            <span className='tracking-wider'>Construction, Architecture</span>
            <h1 className="text-5xl font-bold">
           {project.name}
            </h1>
           {project.HeadPara.map((data,index)=> <p key={data.id} className="text-gray-600 leading-[1.8rem] tracking-wider">
              {data.p}
            </p>)}
            
            <div className='w-[100%] mt-10 flex gap-10'>
                <div className='w-[60%] flex flex-col gap-5'>
                    <h1 className="text-2xl font-bold">
                    {project.h1}
                    </h1>
                    <p className="text-gray-600 leading-[1.8rem] tracking-wider">
              {project.h1p}
            </p>
            <h1 className="text-2xl font-bold">
            {project.h2}
                    </h1>
                    <p className="text-gray-600 leading-[1.8rem] tracking-wider">
                        {project.h2p}
            </p>
            <div className='py-4 flex flex-col gap-3'>

                {project.Points.map((data)=><div key={data.id} className='flex gap-3 items-center text-md font-bold'>
                <FaCheckCircle className='text-orange-600' /><span>{data.p} </span>
                </div>)}
               
            </div>
            <p className="text-gray-600 leading-[1.8rem] tracking-wider">
               {project.h2p1}
            </p>
                </div>
                <div className='w-[40%] h-fit bg-slate-100 p-[2rem] '>
                <div className="relative w-fit text-xl font-bold tracking-wide">
                <h1>Project Information</h1>
                <div className="absolute border-b-[3px] bottom-[-1rem] left-0 border-black w-[30%]" />
                <div className="absolute border-b-[3px] bottom-[-1rem] border-orange-500 left-[35%] w-[60%]" />
              </div>
              <div className="flex w-[100%] flex-col gap-6 mt-12">
                <div className="flex gap-2 items-center">
                    <h1 className='font-semibold text-lg'>
                    Project Category:
                    </h1>
                    <span className='text-gray-600'>
                    {project.ProjectInfo.Category}
                    </span>
                </div>
                <div className="w-[100%] border-b-[1px] border-gray-300 " />
                <div className="flex gap-2 items-center">
                    <h1 className='font-semibold text-lg'>
                    Clients:
                    </h1>
                    <span className='text-gray-600'>
                    {project.ProjectInfo.Clients}
                    </span>
                </div>
                <div className="w-[100%] border-b-[1px] border-gray-300 " />
                <div className="flex gap-2 items-center">
                    <h1 className='font-semibold text-lg'>
                    Project Date:
                    </h1>
                    <span className='text-gray-600'>
                    {project.ProjectInfo.Date}
                    </span>
                </div>
                <div className="w-[100%] border-b-[1px] border-gray-300 " />
                <div className="flex gap-2 items-center">
                    <h1 className='font-semibold text-lg'>
                    Avenue End Date:
                    </h1>
                    <span className='text-gray-600'>
                    {project.ProjectInfo.EndDate}
                                        </span>
                </div>
                <div className="w-[100%] border-b-[1px] border-gray-300 " />
                <div className="flex gap-2 items-center">
                    <h1 className='font-semibold text-lg'>
                    Locations:
                    </h1>
                    <span className='text-gray-600'>
                    {project.ProjectInfo.Location}
                    </span>
                </div>
                <div className="w-[100%] border-b-[1px] border-gray-300 " />
                <div className="flex gap-2 items-center">
                    <h1 className='font-semibold text-lg'>
                    Price After:
                    </h1>
                    <span className='text-gray-600'>
                    {project.ProjectInfo.Price}
                    </span>
                </div>
                
              </div>

                </div>

            </div>
            <div className="w-[100%] flex-col  flex gap-5">
                <h1 className="text-2xl font-bold">{project.h3}</h1>
                <div className="w-[100%] flex gap-10">
                <div className="w-[50%]  flex flex-col gap-5">
                <p className="text-gray-600 leading-[1.8rem] tracking-wider">
              {project.h3p}
            </p>
            <img src={project.h3Img.img} className='w-[100%] h-[20rem]' alt="projects" />
                </div>
                <div className="w-[50%] flex flex-col gap-5 ">
                <p className="text-gray-600 leading-[1.8rem] tracking-wider">
                {project.h3p2}    
            </p>
            <img src={project.h3Img.img1} className='w-[100%] h-[20rem]' alt="projects" />
                </div>
                </div>
            </div>
        </div>}
      </div>
    </section>
  )
}
