import React from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { IoConstructSharp } from "react-icons/io5";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
export default function Offer() {
  return (
    <section className="w-[100vw] flex items-centr justify-center overflow-hidden bg-[#252525] relative">
      <div
        style={{ zIndex: 0 }}
        className="absolute z-0 top-0 left-0 h-[100%] w-[35%] bg-[#053357]"
      />
      <div
        style={{ zIndex: 2 }}
        className="max-w-[1400px] w-[100%] flex flex-col items-center justify-center px-[2rem] py-[5rem]"
      >
        <div className="w-[100%] flex flex-col gap-10 items-center justify-center">
          <div className="w-[100%] flex items-center justify-center gap-10">
            <div className="w-[4rem] max-lg:hidden text-2xl h-[4rem] custom-prev flex items-center justify-center bg-white text-black rounded-full">
              <FaArrowLeft />
            </div>
            <div className="flex flex-col gap-3 items-center justify-center">

            <div className="flex items-center gap-3 text-[#f34e38]">
              <FaArrowLeft className="text-xl" />{" "}
              <h1 className="text-xl font-semibold uppercase tracking-wider">
              Our Constructs 
              </h1>
              <FaArrowRight className="text-xl" />
            </div>
            <span className="max-lg:text-3xl lg:text-5xl font-bold text-white lg:w-[70%] text-center">
            Discover our legacy of successful projects.
            </span>
            </div>
            <div className="w-[4rem] custom-next  max-lg:hidden  text-2xl h-[4rem] flex items-center justify-center bg-transparent border-[2px] border-white text-white rounded-full">
              <FaArrowRight />
            </div>
          </div>

          <div className="w-[100%] flex ">
          <Swiper
              navigation={{
                prevEl: ".custom-prev", // Target custom classes for navigation
                nextEl: ".custom-next",
              }}
              breakpoints={{
                640: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 30,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 30,
                },
              }}
                spaceBetween={30} // Space between slides
              // Show one slide at a time
                loop={true} // Enable looping
                speed={2000} // Slow transition speed in ms
                autoplay={{
                  delay: 1000, // Delay between transitions in ms
                  disableOnInteraction: false, // Continue autoplay even after interaction
                }}
                pagination={{ clickable: true }} // Show pagination dots
              
                modules={[Autoplay, Navigation, Pagination]}
                className="w-[100%] h-[100%] flex "
              >
               {[
                {
                    number:'01',
                    name:'Shri Sai Fuel point-Jeergal Village Mudhol Taluk',
                    image:'1.jpeg',
                    id:1
                },
                {
                    number:'02',
                    name:'Mudhol Road-Bagalkot Districty',
                    image:'2.jpeg',
                    id:2
                },
                {
                    number:'03',
                    name:'Raichur Bachi SH-20 Road-Bagalkot Taluk',
                     image:'33.jpeg',
                     id:3,
                },
                {
                    number:'04',
                    name:'Ramdurga-Manvi Hungunda Taluk, bagalkot district',
                     image:'44.jpeg',
                     id:4
                }
               ].map((data,index)=> <SwiperSlide key={index}>

            <div className="w-[100%] flex flex-col bg-white p-3">
                <div className="h-[17rem] w-[100%]  relative ">
                    <div className="text-5xl p-2 shadow-md bg-white absolute bottom-[-2rem] text-[#f34839] left-[10%] ">
                    <IoConstructSharp />
                    </div>
                    <img src={require('../../Assets/'+data.image)} alt="offer" className="w-[100%] h-[100%] object-cover" />
                </div>
                <h1 className="text-2xl mt-14 font-bold relative hover:text-orange-500 duration-200">
                {data.name}
                    <div className="absolute right-4 top-[-3rem] ">
                        <h1 className="text-[3rem] text-gray-200">
                          {data.number}
                        </h1>
                    </div>
                </h1>
                <p className="mt-6 tracking-wide">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi quisquam laboriosam et eveniet</p>
                <div className="border-[1px] border-gray-300 my-4"/>
               <a href={"/project/"+data.id}>
                <div className="flex gap-3 items-center font-semibold hover:text-orange-500 duration-300">
                    <h1>Explore More</h1><FaArrowRight />
                </div>
               </a>
            </div>
                </SwiperSlide>)}
            </Swiper>

          </div>
        </div>
      </div>
    </section>
  );
}
