import React from "react";
import { FaArrowRight, FaCheckCircle, FaDownload, FaFilePdf } from "react-icons/fa";
import { GrPlan } from "react-icons/gr";
import { MdPaid } from "react-icons/md";
import { SiAffinitydesigner } from "react-icons/si";
export default function Details() {
  return (
    <section className="w-[1000vw]h-[100%] flex flex-col items-center justify-center overflow-hidden">
      <div className="max-w-[1400px] w-[100%] px-[7%] pt-[6rem] pb-[2rem]">
        <div className="w-[100%] flex gap-10">
          <div className="w-[65%]  flex flex-col gap-5">
            <div className="w-[100%] h-[20rem] overflow-hidden">
              <img
                src={require("../../Assets/01 (2).webp")}
                className="w-[100%] h-[100%] object-cover"
                alt=""
              />
            </div>
            <h1 className="text-5xl font-bold">Building Construction</h1>
            <p className="text-gray-600 leading-[1.8rem] tracking-wide">
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Cumque
              repellat aperiam dolorem autem laudantium asperiores officia
              laboriosam suscipit laborum placeat veritatis sit, nam, cupiditate
              exercitationem ex delectus quod doloribus non.
            </p>
            <p className="text-gray-600 leading-[1.8rem] tracking-wide">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad, at
              deserunt in obcaecati nobis, ex recusandae odit quaerat, assumenda
              quam odio error laudantium atque neque illum aperiam sunt quod
              qui.
            </p>
            <div className="w-[100%] mt-5 flex gap-10">
              <div className="w-[55%]">
                <img src={require("../../Assets/01 (1).webp")} alt="" />
              </div>
              <div className="w-[45%] flex flex-col gap-3">
                <h1 className="text-2xl font-bold ">Services Benefits:</h1>
                <p className="text-gray-600 leading-[1.8rem] text-lg">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Placeat aut magnam itaque delectus pariatur maxime!
                  Repellendus, nulla nesciunt error quia at.
                </p>
                <div className="flex flex-col gap-5">
                    <div className=" text-md font-semibold tracking-wide">
                    <FaCheckCircle className="text-[20px] text-orange-500 mr-3 inline-flex " />
                  We use the latest diagnostic equipment
                    </div>
                    <div className=" text-md font-semibold tracking-wide">
                    <FaCheckCircle className="text-[20px] text-orange-500 mr-3 inline-flex " />
                  We use the latest diagnostic equipment
                    </div>
                    <div className=" text-md font-semibold tracking-wide">
                    <FaCheckCircle className="text-[20px] text-orange-500 mr-3 inline-flex " />
                  We use the latest diagnostic equipment
                    </div>
                    <div className=" text-md font-semibold tracking-wide">
                    <FaCheckCircle className="text-[20px] text-orange-500 mr-3 inline-flex " />
                  We use the latest diagnostic equipment
                    </div>
                </div>
              </div>
            </div>
            <div className="w-[100%] flex flex-col gap-5">
                <h1 className="text-4xl font-bold">
                3 Simple Steps to Process
                </h1>
                <p className="text-gray-600 tracking-wide leading-[1.7rem]">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad et obcaecati sit quam quasi temporibus tenetur. Fuga vitae deserunt in, quaerat quia iste laboriosam voluptatibus mollitia sapiente. Libero, qui quidem.
                </p>
                <div className="w-[100%] grid grid-cols-3 gap-5">
                    <div className="w-[100%] flex flex-col gap-4 border-[1px] border-gray-300 hover:border-orange-500 duration-300 p-[2rem]">
                   <div className="w-[100%] flex items-center  gap-3">
                    <GrPlan  className='text-[30px] text-orange-500 '/>
                    <h1 className="font-bold text-lg"> Planning</h1>
                   </div>
                   <p className="text-[16px] leading-[2rem] tracking-wide text-gray-600">
                   There are many Xbuild a variations of passages of Lorem Ipsum
                   </p>
                    </div>
                    <div className="w-[100%] flex flex-col gap-4 border-[1px] border-gray-300 hover:border-orange-500 duration-300 p-[2rem]">
                   <div className="w-[100%] flex items-center  gap-3">
                    <SiAffinitydesigner   className='text-[30px] text-orange-500 '/>
                    <h1 className="font-bold text-lg"> Design</h1>
                   </div>
                   <p className="text-[16px] leading-[2rem] tracking-wide text-gray-600">
                   There are many Xbuild a variations of passages of Lorem Ipsum
                   </p>
                    </div>
                    <div className="w-[100%] flex flex-col gap-4 border-[1px] border-gray-300 hover:border-orange-500 duration-300 p-[2rem]">
                   <div className="w-[100%] flex items-center  gap-3">
                    <MdPaid  className='text-[30px] text-orange-500 '/>
                    <h1 className="font-bold text-lg"> Get Paid</h1>
                   </div>
                   <p className="text-[16px] leading-[2rem] tracking-wide text-gray-600">
                   There are many Xbuild a variations of passages of Lorem Ipsum
                   </p>
                    </div>
                </div>
                <p className="text-gray-600 leading-[1.8rem] tracking-wide">
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illum ut impedit enim obcaecati repellendus exercitationem, recusandae earum nihil ipsa quo odio? Reiciendis, quidem. Quisquam inventore totam quia impedit excepturi reiciendis.
                </p>
            </div>
          </div>
          <div className="w-[30%] flex flex-col gap-5">
            <div className="px-[1.5rem] flex flex-col bg-slate-100 py-[2.5rem]">
                <div className="relative w-fit text-xl font-semibold tracking-wide">
                    <h1>Categories</h1>
                    <div className="absolute border-b-[3px] bottom-[-1rem] left-0 border-black w-[30%]"/>
                    <div className="absolute border-b-[3px] bottom-[-1rem] border-orange-500 left-[35%] w-[60%]"/>
                </div>
                <div className="w-[100%] mt-10 flex flex-col gap-4">
                    <div className="w-[100%] bg-white text-black hover:bg-orange-500 hover:text-white duration-300 flex items-center justify-between px-4 py-4">
                        <h1 className="text-lg">Construction</h1><FaArrowRight />
                    </div>
                    <div className="w-[100%] bg-white text-black hover:bg-orange-500 hover:text-white duration-300 flex items-center justify-between px-4 py-4">
                        <h1 className="text-lg">House Renovation</h1><FaArrowRight />
                    </div>
                    <div className="w-[100%] bg-white text-black hover:bg-orange-500 hover:text-white duration-300 flex items-center justify-between px-4 py-4">
                        <h1 className="text-lg">Material Supply</h1><FaArrowRight />
                    </div>
                    <div className="w-[100%] bg-white text-black hover:bg-orange-500 hover:text-white duration-300 flex items-center justify-between px-4 py-4">
                        <h1 className="text-lg">Project Management</h1><FaArrowRight />
                    </div>
                    <div className="w-[100%] bg-white text-black hover:bg-orange-500 hover:text-white duration-300 flex items-center justify-between px-4 py-4">
                        <h1 className="text-lg">Heating and Water</h1><FaArrowRight />
                    </div>

                </div>

            </div>
            <div className="px-[1.5rem] flex flex-col bg-slate-100 py-[2.5rem]">
                <div className="relative w-fit text-xl font-semibold tracking-wide">
                    <h1>Download</h1>
                    <div className="absolute border-b-[3px] bottom-[-1rem] left-0 border-black w-[30%]"/>
                    <div className="absolute border-b-[3px] bottom-[-1rem] border-orange-500 left-[35%] w-[60%]"/>
                </div>
                <div className="mt-10 flex flex-col gap-5">
                    <div className="flex w-[100%] items-center justify-between">
                        <div className="flex items-center gap-2">
                        <FaFilePdf className="text-[50px] text-orange-500" />
                        <div className="flex flex-col ">
                            <h1 className="font-bold text-lg">
                            Our Brochures
                            </h1>
                            <span className="text-md text-gray-500">
                                Download

                            </span>

                        </div>
                        </div>
                        <div className="bg-black text-white w-[3rem] h-[3rem] flex items-center justify-center hover:bg-orange-500 duration-300 ">
                        <FaDownload />
                        </div>
                    </div>
                    <div className="flex w-[100%] items-center justify-between">
                        <div className="flex items-center gap-2">
                        <FaFilePdf className="text-[50px] text-orange-500" />
                        <div className="flex flex-col ">
                            <h1 className="font-bold text-lg">
                           
Company Details
                            </h1>
                            <span className="text-md text-gray-500">
                                Download

                            </span>

                        </div>
                        </div>
                        <div className="hover:bg-black text-white w-[3rem] h-[3rem] flex items-center justify-center bg-orange-500 duration-300 ">
                        <FaDownload />
                        </div>
                    </div>
                </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
