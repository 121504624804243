import React, { useState } from "react";
import Header from "./Header";
import Banner from "./Banner";
import About from "./About";
import Offer from "./Offer";
import Connect from "./Connect";
import Skills from "./Skills";
import Testimonials from "./Testimonials";
import Projects from "./Projects";
import Faq from "./Faq";
import Team from "./Team";
import Contact from "./Contact";
import Blog from "./Blog";
import Client from "./Client";
import Footer from "./Footer";
import Address from "../Menu/Address";
import Vedio from "./Vedio";

export default function HomeCOntainer() {
 const [card,setCard]= useState(false)
 const [video,setVideo]=useState(false)
  return (
    <>
      <Header setCard={setCard}/>

      <Address setCard={setCard} card={card} />
      <div className="relative w-[100%] h-[100%]">
      <div className={` ${ card&&'w-[100%] absolute left-0  top-0  h-[100%] z-10 bg-[#080808bd]'}`} />
      <Banner />
      <About  setVideo={setVideo}/>
      {video && <Vedio setVideo={setVideo}/>}
      <Offer />
      <Connect />
      {/* <Skills /> */}
      <Testimonials />
      {/* <Projects /> */}
      {/* <Faq /> */}
      <Team />
      <Contact />
      {/* <Blog /> */}
      <Client />
      <Footer />
      </div>
    </>
  );
}
