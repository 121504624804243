import { motion } from 'framer-motion'
import React from 'react'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { IoConstructSharp } from 'react-icons/io5'
export default function Details() {
  return (
    <section className="w-[100vw] flex items-center justify-center overflow-hidden">
      <div className="w-[100%] max-w-[1400px] flex-col flex px-[3%] py-[7rem] justify-center items-center">
        <div className="w-[100%] items-center justify-center flex flex-col gap-5">
          <div className="flex w-[100%] items-center justify-center gap-3 text-[#ff523c]">
            <FaArrowLeft className="text-xl" />{" "}
            <h1 className="text-md font-semibold uppercase tracking-wider">
            WHAT WE OFFER
            </h1>
            <FaArrowRight className="text-xl" />
          </div>
          <h1 className="text-5xl  font-bold text-center">Create The Building <br/>You Want Here</h1>
          </div>
          <div className='w-[100%]  grid grid-cols-3 px-[7%] gap-5 mt-10'>
          {[
                {
                    number:'01',
                    name:'Building Construction',
                    img:'1.jpeg'

                },
                {
                    number:'02',
                    name:'Building Design',
                     img:'21.jpeg'
                },
                {
                    number:'03',
                    name:'Building Maintenance',
                     img:'23.jpeg'
                },
                {
                    number:'04',
                    name:'Building Renovation',
                     img:'12.jpeg'
                },
                {
                    number:'05',
                    name:'Building Renovation',
                     img:'24.jpeg'
                },
                {
                    number:'06',
                    name:'Building Renovation',
                     img:'2.jpeg'
                }
               ].map((data,index)=>
            <motion.div
            initial={{ opacity: 0 }}
            viewport={{once:true}}
            whileInView={{
                    opacity:1,
                    transition:{
                        duration:0.5,
                        delay:index*0.3
                    }
            }}
            key={index} className="w-[100%] flex flex-col bg-slate-100 p-3">
                <div className="h-[17rem] w-[100%]  relative ">
                    <div className="text-5xl p-2 shadow-md bg-white absolute bottom-[-2rem] text-[#f34839] left-[10%] ">
                    <IoConstructSharp />
                    </div>
                    <img src={require('../../New/'+data.img)} alt="offer" className="w-[100%] h-[100%] object-cover" />
                </div>
                <h1 className="text-2xl mt-14 font-bold relative hover:text-orange-500 duration-200">
                {data.name}
                    <div className="absolute right-4 top-[-3rem] ">
                        <h1 className="text-[3rem] text-gray-200">
                          {data.number}
                        </h1>
                    </div>
                </h1>
                <p className="mt-6 tracking-wide">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi quisquam laboriosam et eveniet</p>
                <div className="border-[1px] border-gray-300 my-4"/>
                <a href="/service/1">
                <div className="flex gap-3 items-center font-semibold hover:text-orange-500 duration-300">
                  
                    <h1>Explore More</h1><FaArrowRight />
                </div>
                </a>
            </motion.div>
               )}

          </div>
    </div>
    </section>
  )
}
