import React from 'react'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { FaLocationDot } from 'react-icons/fa6'
import { MdOutlineMail } from 'react-icons/md'
import { BiTimer } from "react-icons/bi";
export default function Contains() {
  return (
    <section className="w-[100vw] flex items-center justify-center overflow-hidden">
    <div className="w-[100%] max-w-[1400px] flex-col flex px-[10%] py-[7rem] justify-center items-center">
      <div className="w-[100%] items-center justify-center flex flex-col gap-5">
        <div className="flex w-[100%] items-center justify-center gap-3 text-[#ff523c]">
          <FaArrowLeft className="text-xl" />{" "}
          <h1 className="text-md font-semibold uppercase tracking-wider">
          CONTACT US
          </h1>
          <FaArrowRight className="text-xl" />
        </div>
        <h1 className="text-5xl  font-bold text-center">Our Contact Information</h1>
        </div>
        <div className="w-[100%] mt-10 flex flex-col gap-5 ">
            <div className='grid grid-cols-2 gap-5'>
                <div className='w-[100%] p-[3rem] flex gap-4 bg-slate-100 '>
                    <div className='w-[4rem] bg-white h-[4rem] flex items-center justify-center'>
                    <FaLocationDot className='text-[2rem] text-orange-500' />
                    </div>
                    <div className='flex flex-col w-[90%]'>
                        <h1 className='text-2xl font-bold'>Our Address</h1>
                        <p className='text-gray-500 leading-[1.7rem]'>Appanna Roogi H No 4026/28, M/s A R Roogi & Co, Sidrameshwar Nagar Palace   Road, Mudhol Dist.-Bagalkot, Karnataka-587313</p>
                    </div>
                </div>
                <div className='w-[100%] p-[3rem] flex gap-4 bg-slate-100 '>
                    <div className='w-[4rem] bg-white h-[4rem] flex items-center justify-center'>
                    <MdOutlineMail  className='text-[2rem] text-orange-500' />
                    </div>
                    <div className='flex flex-col gap-1'>
                        <h1 className='text-2xl font-bold'>Email Address</h1>
                        <div className='text-gray-500 flex font-semibold flex-col gap-0'>
                            <span>
                            Mobile : 9448688179
                            </span>
                            <span>
                            Email :roogiappanna@gmail.com
                            </span>
                        </div>
                    </div>
                </div>
                <div className='w-[100%] p-[3rem] flex gap-4 bg-slate-100 '>
                    <div className='w-[4rem] bg-white h-[4rem] flex items-center justify-center'>
                    <BiTimer  className='text-[3rem] text-orange-500' />
                    </div>
                    <div className='flex flex-col w-[50%]'>
                        <h1 className='text-2xl font-bold'>
                        Hours of Operation</h1>
                        <p className='text-gray-500 leading-[1.7rem]'>Monday-Saturday : 8:00 -
                        9:00 Sunday : Closed</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </section>
  )
}
