const data=[
    {
        id:1,
        name:'Sai fuel point - nayara energy',
        img:require('../../Assets/1.jpeg'),
        HeadPara:[
            {
                id:1,
                p:`Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique! Lorem, ipsum dolor sit amet consectetur adipisicing elit. A est voluptatum impedit aperiam sit ducimus nobis nesciunt, minus iusto quos quo recusandae doloremque excepturi beatae vitae. Repudiandae tempore laudantium rerum!`
            },
            {
                id:2,
                p:`Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!`
            }
        ],
        h1:'Building Together. Building Stronger Communities',
        h1p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        h2:'Project Goal',
        h2p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        Points:[
            {
                id:1,
                p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit.'
            },
            {
                id:2,
                p:'Proactively pontificate client-centered relationships'
            },
            {
                id:3,
                p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit.'
            },
            {
                id:4,
                p:'Proactively pontificate client-centered relationships.'
            },
        ],
        h2p1:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        h3:'Project Summery',
        h3p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        h3p2:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        h3Img:
            {
          
                img:require('../../Assets/1.jpeg'),
                img1:require('../../Assets/11.jpeg')
            },
            
     
        ProjectInfo:{
            Category:'Fuel Station',
            Clients:'Sai fuel point - nayara energy',
            Location:'25 June, 2024',
            Date:'NewYork – 4648 Rocky, USA',
            EndDate:'08 July, 2024',
            Price :'$10 Million'

        }
       
    },
    {
        id:2,
        name:'Project 2 - Mudhol city',
        img:require('../../Assets/2.jpeg'),
        HeadPara:[
            {
                id:1,
                p:`Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique! Lorem, ipsum dolor sit amet consectetur adipisicing elit. A est voluptatum impedit aperiam sit ducimus nobis nesciunt, minus iusto quos quo recusandae doloremque excepturi beatae vitae. Repudiandae tempore laudantium rerum!`
            },
            {
                id:2,
                p:`Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!`
            }
        ],
        h1:'Building Together. Building Stronger Communities',
        h1p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        h2:'Project Goal',
        h2p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        Points:[
            {
                id:1,
                p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit.'
            },
            {
                id:2,
                p:'Proactively pontificate client-centered relationships'
            },
            {
                id:3,
                p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit.'
            },
            {
                id:4,
                p:'Proactively pontificate client-centered relationships.'
            },
        ],
        h2p1:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        h3:'Project Summery',
        h3p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        h3p2:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        h3Img:
            {
              
                img:require('../../Assets/2.jpeg'),
                img1:require('../../Assets/44.jpeg')
            },
            
    
        ProjectInfo:{
            Category:'Road Development',
            Clients:'Project 2 - Mudhol city',
            Location:'Mudhol city',
            Date:'08 July, 2024',
            EndDate:'08 July, 2024',
            Price :'$10 Million'

        }
       
    },
    {
        id:3,
        name:'Resurfacing to Raichur Bachi SH-20 Road in Bagalkot Taluk',
        img:require('../../New/1.jpeg'),
        HeadPara:[
            {
                id:1,
                p:`Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique! Lorem, ipsum dolor sit amet consectetur adipisicing elit. A est voluptatum impedit aperiam sit ducimus nobis nesciunt, minus iusto quos quo recusandae doloremque excepturi beatae vitae. Repudiandae tempore laudantium rerum!`
            },
            {
                id:2,
                p:`Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!`
            }
        ],
        h1:'Building Together. Building Stronger Communities',
        h1p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        h2:'Project Goal',
        h2p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        Points:[
            {
                id:1,
                p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit.'
            },
            {
                id:2,
                p:'Proactively pontificate client-centered relationships'
            },
            {
                id:3,
                p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit.'
            },
            {
                id:4,
                p:'Proactively pontificate client-centered relationships.'
            },
        ],
        h2p1:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        h3:'Project Summery',
        h3p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        h3p2:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        h3Img:
            {
              
                img:require('../../New/1.jpeg'),
           
                img1:require('../../New/11.jpeg')
            },
            
     
        ProjectInfo:{
            Category:'Road Development',
            Clients:'State Government',
            Location:' Raichur',
            Date:'08 July, 2024',
            EndDate:'08 July, 2024',
            Price :'$10 Million'

        }
       
    },
    {
        id:4,
        name:'Widening and improvement to road in mudhol city of bagalkot district',
        img:require('../../New/23.jpeg'),
        HeadPara:[
            {
                id:1,
                p:`Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique! Lorem, ipsum dolor sit amet consectetur adipisicing elit. A est voluptatum impedit aperiam sit ducimus nobis nesciunt, minus iusto quos quo recusandae doloremque excepturi beatae vitae. Repudiandae tempore laudantium rerum!`
            },
            {
                id:2,
                p:`Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!`
            }
        ],
        h1:'Building Together. Building Stronger Communities',
        h1p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        h2:'Project Goal',
        h2p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        Points:[
            {
                id:1,
                p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit.'
            },
            {
                id:2,
                p:'Proactively pontificate client-centered relationships'
            },
            {
                id:3,
                p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit.'
            },
            {
                id:4,
                p:'Proactively pontificate client-centered relationships.'
            },
        ],
        h2p1:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        h3:'Project Summery',
        h3p:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        h3p2:'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tenetur rem natus earum ex commodi ducimus id laudantium voluptate nobis laborum alias, quisquam odit possimus odio maxime reiciendis consequuntur! Quisquam, similique!',
        h3Img:
            {
               
                img:require('../../New/21.jpeg'),
         
                img1:require('../../New/23.jpeg')
            },
          
        ProjectInfo:{
            Category:'Road Development',
            Clients:'State Government',
            Location:'Bagalkot district',
            Date:'08 July, 2024',
            EndDate:'08 July, 2024',
            Price :'$10 Million'

        }
       
    }
]
export default data