import React, { useState } from 'react'
import Header from '../Home/Header'
import About from '../Home/About'
import Client from '../Home/Client'
import Footer from '../Home/Footer'
import Testimonials from '../Home/Testimonials'
import Team from '../Home/Team'
import Banner from './Banner'
import Achivments from './Achivments'
import Address from '../Menu/Address'
import Vedio from '../Home/Vedio'

export default function AboutCover() {
  const [card,setCard]= useState(false)
  const [video,setVideo]= useState(false)

    return (
      <>
        <Header setCard={setCard}/>
  
        <Address setCard={setCard} card={card} />
        <div className="relative w-[100%] h-[100%]">
        <div className={` ${ card&&'w-[100%] absolute left-0  top-0  h-[100%] z-10 bg-[#080808bd]'}`} />
     <Banner title="About Us" name='About Us'/>
     <Achivments/>
     <About  setVideo={setVideo}/>
     {video && <Vedio setVideo={setVideo}/>} 
     <Testimonials/>
     <Team/>
     <Client/>
     <Footer/>
     </div>
    </>
  )
}
