import React, { useEffect, useState } from "react";
import { FaArrowRight, FaPhoneAlt } from "react-icons/fa";
import { FaLocationDot } from "react-icons/fa6";
import { IoMdMenu } from "react-icons/io";
import { MdOutlineMailOutline } from "react-icons/md";

export default function Header({setCard}) {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Check if the scroll position is greater than the navbar's original position
      if (window.scrollY > 160) {
        // Adjust the threshold as needed
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav
    
      className={`${
        isFixed ? "fixed z-99999 duration-500 " : "relative"
      } w-[100vw] duration-500 transition-all bg-white flex z-30 text-[#252525] items-center justify-center overflow-x-hidden`}
    >
      <div className="max-w-[1400px] relative flex items-center justify-center w-[100%] max-md:px-[5%] md:px-[3rem] pb-3">
        <div className="w-[100%] flex items-center gap-20 justify-between">
          <img
            src={require("../../Assets/logos.png")}
            className="w-[12rem]"
            alt=" logo"
          />
          <div className="flex flex-col gap-4">
            <div
              className={`${
                isFixed && "hidden"
              } grid grid-cols-3 max-lg:hidden  ml-[5%] p-[1rem] gap-5 items-center`}
            >
              <div className="w-[100%] items-center flex gap-2">
                <div className="w-[3rem] bg-[#04355A] h-[3rem] flex items-center justify-center border-[1px] border-gray-400 rounded-full">
                  <FaLocationDot className="text-xl text-[#ff793a]" />
                </div>
                <div className="flex w-[90%] flex-col gap-1">
                  <h1 className="text-sm text-gray-500 font-[600]">
                    Office location
                  </h1>
                  <p className="text-[14px] font-bold ">Sidrameshwar Nagar , Mudhol Dist-Bagalkot.</p>
                </div>
              </div>
              <div className="w-[100%] items-center flex gap-2">
                <div className="w-[3rem] h-[3rem] bg-[#04355A]  flex items-center justify-center border-[1px] border-gray-400 rounded-full">
                  <MdOutlineMailOutline className="text-xl text-[#ff793a]" />
                </div>
                <div className="flex flex-col gap-1">
                  <a href="#" className="text-sm text-gray-500 font-[600]">
                    send email
                  </a>
                  <p className="text-[14px] font-bold ">roogiappanna@gmail.com</p>
                </div>
              </div>
              <div className="w-[100%] items-center flex gap-2">
                <div className="w-[3rem] bg-[#04355A] h-[3rem] flex items-center justify-center border-[1px] border-gray-400 rounded-full">
                  <FaPhoneAlt className="text-xl text-[#ff793a]" />
                </div>
                <div className="flex flex-col gap-1">
                  <h1 className="text-sm text-gray-500 font-[600]">
                    contact now
                  </h1>
                  <p className="text-[14px] font-bold ">9448688179</p>
                </div>
              </div>
              {/* <div className="header-button flex gap-2">
                <a
                  className="theme-btn bg-[#ff793a] text-white flex gap-2 items-center"
                  href="https://adorable-genie-784ab0.netlify.app/"
                >
                  Real Estate <FaArrowRight />
                </a>
              </div> */}
            </div>
            <div className="flex items-center py-3 max-lg:hidden justify-center gap-20">
              <div className="flex items-center text-[17px] tracking-wider font-[600] gap-16">
                <a
                  href="/"
                  className="hover:text-orange-500 duration-500 cursor-pointer"
                >
                  Home
                </a>
                <a
                  href="/about"
                  className="hover:text-orange-500 duration-500 cursor-pointer"
                >
                  About
                </a>
                {/* <a
                  href="/team"
                  className="hover:text-orange-500 duration-500 cursor-pointer"
                >
                  Our Team
                </a> */}
                <div
                  
                  className="hover:text-orange-500 duration-500 cursor-pointer"
                >
                 <select  className=" w-[13rem] text-black relative outline-none px-3 py-3">
              
                  <option defaultValue="OUR EXPERTISE">OUR EXPERTISE</option>
                  <option value="Road ">Road </option>
                  <option value="Roogi stone crushers">Roogi stone crushers</option>
                  <option value="Roogi Real Estate ">Roogi Real Estate </option>
                  <option value="Roogi Fuel Point constructs">Roogi Fuel Point constructs</option>
                  </select>
                </div>
                <a
                  href="/projects"
                  className="hover:text-orange-500 duration-500 cursor-pointer"
                >
                  Projects
                </a>
                {/* <a
                  href="/blog"
                  className="hover:text-orange-500 duration-500 cursor-pointer"
                >
                  Blogs
                </a> */}
                <a
                  href="/contact"
                  className="hover:text-orange-500 duration-500 cursor-pointer"
                >
                  Contact
                </a>
              </div>
              <div onClick={()=>setCard(true)}>
                <IoMdMenu className="text-[25px] text-gray-800" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}
