import { motion } from 'framer-motion'
import React, { useState } from 'react'

export default function Projectss({title,image,id}) {
    const [card,setCard]= useState(false)
  return (
    <div onMouseEnter={()=>setCard(true)} onMouseLeave={()=>setCard(false)} className="w-[100%] border-[1px] relative border-gray-200 pb-[2rem] flex flex-col gap-5">
    <img src={require('../../Assets/shape.webp')} className='absolute bottom-0 right-0' alt="" />
    <div className='w-[100%] relative h-[20rem] overflow-fidden'>
    <motion.div 
      animate={{
     
      rotateY:card?90:0,
      display:card?0:'auto',
        left:0,
        transition:{
            duration:0.5,
            ease:'easeInOut'
        }
      }}
      className='h-[100%] absolute top-0 left-0 w-[100%]'> 

        <img src={image} className='w-[100%] h-[100%] object-cover' alt="" />
      </motion.div>
      <motion.div 
     
     animate={{
     
        right:0,
        rotateY:!card?90:0,
        transition:{
            duration:0.5,
            ease:'easeInOut'
        }
      }}
      className='h-[100%]  absolute top-0 right-0 w-[100%]'> 

        <img src={image} className='w-[100%] h-[100%] object-cover' alt="" />
      </motion.div>
    </div>
    <div className='px-4 flex flex-col gap-3'>
        <a href={"/project/"+id}>
        <h1 className='text-2xl font-bold hover:text-orange-500 duration-300'>{title}</h1>
        </a>
        <p className='text-gray-500 leading-[2rem]'>There are many variations of a passages of Lorem Ipsum available.</p>
    </div>
</div>
  )
}
