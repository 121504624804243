import React from "react";
import { FaFacebookF, FaInstagram, FaPhoneVolume } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import { MdEditLocation, MdEmail } from "react-icons/md";

export default function Footer() {
  return (
    <section className="w-[100vw] bg6 text-white flex items-center justify-center overflow-hidden">
      <div className="w-[100%] flex-col bg-[#000000c4] max-w-[1400px] flex px-[5%] max-lg:pt-[3rem] lg:pt-[7rem] justify-center items-center">
        <div className="flex gap-7 max-lg:hidden items-center w-[100%]">
          <div
            className="
            rounded-full overflow-hidden w-[15rem]
            "
          >
            <img src={require("../../Assets/logo.png")} alt="" />
          </div>
          <div className="flex gap-5 items-centergap-3">
            <div className="w-[3rem] h-[3rem] text-2xl flex items-center justify-center text-[#fd9824] bg-white rounded-full">
              <MdEditLocation />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-gray-300 font-bold">Office location</span>
              <h1 className="text-xl font-bold">Mudhol Dist.-Bagalkot, Karnataka</h1>
            </div>
          </div>
          <div className="flex gap-5 items-centergap-3">
            <div className="w-[3rem] h-[3rem] text-2xl flex items-center justify-center text-[#fd9824] bg-white rounded-full">
              <IoMdMail />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-gray-300 font-bold">send email</span>
              <h1 className="text-xl font-bold">roogiappanna@gmail.com</h1>
            </div>
          </div>
          <div className="flex gap-5 items-centergap-3">
            <div className="w-[3rem] h-[3rem] text-2xl flex items-center justify-center text-[#fd9824] bg-white rounded-full">
              <FaPhoneVolume />
            </div>
            <div className="flex flex-col gap-1">
              <span className="text-gray-300 font-bold">call emergency</span>
              <h1 className="text-xl font-bold">9448688179 </h1>
            </div>
          </div>
        </div>
        <div className="w-[100%] max-lg:hidden my-10 border-gray-300 border-t-[1px]" />
        <div className="w-[100%] lg:flex max-lg:flex max-lg:flex-col max-lg:gap-5 lg:justify-around">
          <div className="lg:w-[30%] flex flex-col gap-7">
            <div className="relative w-fit flex flex-col gap-1">
              <h1 className="text-xl font-bold ">About Company</h1>
              <div className="absolute bottom-[-0.5rem] border-t-[2px] left-0 w-[30%] border-t-[#ffa42c]" />
              <div className="absolute bottom-[-0.5rem] border-t-[2px] left-[40%] w-[50%] border-t-[#ffffff]" />
            </div>
            <p>
            Roohi Group of companies are leading civil government contracting firm specializing in providing comprehensive solutions for public sector infrastructure projects.
            </p>
            <div className="flex items-center gap-5 text-2xl">
                <div className="border-[1px] border-gray-100 flex items-center justify-center w-[2.5rem] h-[2.5rem]">
                <MdEmail />
                </div>
                <div className="border-[1px] border-gray-100 flex items-center justify-center w-[2.5rem] h-[2.5rem]">
                <FaInstagram />
                </div>
                <div className="border-[1px] border-gray-100 flex items-center justify-center w-[2.5rem] h-[2.5rem]">
                <FaFacebookF />
                </div>
                <div className="border-[1px] border-gray-100 flex items-center justify-center w-[2.5rem] h-[2.5rem]">
                <FaXTwitter />
                </div>
            </div>
          </div>
          <div className=" flex flex-col gap-7">
            <div className="relative w-fit flex flex-col gap-1">
              <h1 className="text-xl font-bold ">Quick Link</h1>
              <div className="absolute bottom-[-0.5rem] border-t-[2px] left-0 w-[30%] border-t-[#ffa42c]" />
              <div className="absolute bottom-[-0.5rem] border-t-[2px] left-[40%] w-[50%] border-t-[#ffffff]" />
            </div>
          <div className="flex uppercase text-md tracking-wide font-semibold flex-col gap-5">
            <span>
              <a href="/about">
                About us
              </a>
            </span>
            <span>
              <a href="/contact">
            Contact Us
              </a>
            </span>
            <span>
              <a href="/service">
          Services
              </a>
            </span>
            <span>
              <a href="/projects">
          Projects
              </a>
            </span>
            {/* <span>
              <a href="/blog">
            Blog & News
              </a>
            </span> */}
            {/* <span>
            FAQ’S
            </span> */}
            {/* <span>
            FAQ’S
            </span> */}
          </div>
        
          </div>
          <div className=" flex flex-col gap-7">
            <div className="relative w-fit flex flex-col gap-1">
              <h1 className="text-xl font-bold ">Our Expertise</h1>
              <div className="absolute bottom-[-0.5rem] border-t-[2px] left-0 w-[30%] border-t-[#ffa42c]" />
              <div className="absolute bottom-[-0.5rem] border-t-[2px] left-[40%] w-[50%] border-t-[#ffffff]" />
            </div>
          <div className="flex uppercase text-md tracking-wide font-semibold flex-col gap-5">
            <span>
            Road Constructions
            </span>
            <span>
            Real estate
            </span>
            <span>
            A R Roogi Stone Crushers
            </span>
            <span>
            Fuel Points Project
            </span>
          </div>
        
          </div>
          <div className=" flex flex-col lg:w-[30%] gap-7">
            <div className="relative w-fit flex flex-col gap-1">
              <h1 className="text-xl font-bold ">Instagram</h1>
              <div className="absolute bottom-[-0.5rem] border-t-[2px] left-0 w-[30%] border-t-[#ffa42c]" />
              <div className="absolute bottom-[-0.5rem] border-t-[2px] left-[40%] w-[50%] border-t-[#ffffff]" />
            </div>
         <div className="grid grid-cols-3 gap-4">
           <img src={require('../../Assets/1.jpeg')} alt="" className="h-[5rem] object-cover w-[100%]" />
           <img src={require('../../Assets/2.jpeg')} alt="" className="h-[5rem] object-cover w-[100%]" />
           <img src={require('../../Assets/44.jpeg')} alt="" className="h-[5rem] object-cover w-[100%]" />
           <img src={require('../../New/1.jpeg')} alt="" className="h-[5rem] object-cover w-[100%]" />
           <img src={require('../../New/2.jpeg')} alt=""  className="h-[5rem] object-cover w-[100%]"/>
           <img src={require('../../New/24.jpeg')} alt="" className="h-[5rem] object-cover w-[100%]" />

         </div>
        
          </div>
        </div>
        <div className=" border-t-[1px] border-gray-600 w-[100%] mt-[4rem]" />
        <h1 className="lg:text-xl max-lg:text-md py-[2rem] font-bold text-center text-white">
        © All Copyright 2024 by <span className="text-[#8523f5]">UNITECHNO</span>
        </h1>
      </div>
    </section>
  );
}
