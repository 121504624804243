import { motion } from 'framer-motion'
import React, { useState } from 'react'
import Projectss from '../UI/Projectss'
import img1 from '../../Assets/1.jpeg'
import img2 from '../../Assets/2.jpeg'
import img3 from '../../New/1.jpeg'
import img4 from '../../New/22.jpeg'
export default function Project() {
   
  return (
    <section className='w-[100vw] h-[100%] overflow-hidden flex justify-center items-center'>
      <div className="max-w-[1400px] w-[100%] px-[9%] py-[7rem]">
        <div className='w-[100%] grid grid-cols-3 gap-5'> 
            <Projectss title={'Sai fuel point - nayara energy'} image={img1} id={1}/>
            <Projectss title={'Project 2 - Mudhol city'} image={img2} id={2}/>
            <Projectss title={'Resurfacing to Raichur Bachi SH-20 Road in Bagalkot Taluk'} image={img3} id={3}/>
            <Projectss title={'Widening and improvement to road in mudhol city of bagalkot district'} image={img4} id={4}/>
            <Projectss title={'Contemporary Villa'} image={img1} id={1}/>
            <Projectss title={'Project 2 - Mudhol city'} image={img3} id={1}/> 
        </div>
      </div>
    </section>
  )
}
