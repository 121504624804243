import React from 'react'
import { IoCloseCircleSharp } from 'react-icons/io5'

export default function Vedio({setVideo}) {
  return (
    <section className='w-[100vw] h-[100vh] fixed top-0 z-40  flex items-center justify-center overflow-hidden'>
        <div className='w-[100%]  bg-[#000000d0] max-w-[1400px] flex flex-col flex-1 px-[7%] py-[7rem] justify-center items-center gap-5'>
         <div onClick={()=>setVideo(false)} className='absolute right-[10%] top-[2rem]'>
         <IoCloseCircleSharp className='text-[2rem] text-orange-500' />

         </div>
          <div className='flex w-[100%] items-center  justify-center gap-3 text-[#ff523c]'>
            <div className='relative w-[80%] bg-white h-[30rem]'>
              <video
                src={require('../../Assets/v1.mp4')}
                autoPlay
                muted
                loop
                className='w-[100%] h-[100%] object-cover absolute top-0 left-0'
              />
            </div>
          </div>
        </div>
      
    </section>
  )
}
