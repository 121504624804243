import React from "react";
import { FaArrowLeft, FaArrowRight, FaQuoteLeft } from "react-icons/fa";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { motion } from "framer-motion";
export default function Testimonials() {
  return (
    <section className="w-[100vw] text-white flex items-center justify-center overflow-hidden bg-[#1e2023]">
      <div className="w-[100%] relative max-w-[1400px] flex items-center max-lg:px-[3%] lg:pl-[10%] ">
        <motion.div
        animate={{
         y:[0,20,0],
         transition:{
            duration:4,
            repeat:Infinity,
            repeatType:'loop'
         }   
        }}
        className="absolute top-0 left-0">
            <img src={require('../../Assets/arrow-shape.webp')} alt="testinomial" />
        </motion.div>
        <div className="w-[100%] lg:flex max-lg:flex max-lg:flex-col max-lg:gap-5 ">
          <div className="lg:w-[55%] relative py-[4rem] flex flex-col gap-5">
           
            <div className="flex w-[100%] items-center gap-3 text-[#ff533c]">
              <FaArrowLeft className="text-xl" />{" "}
              <h1 className="text-xl font-semibold uppercase tracking-wider">
                1976 Building company
              </h1>
              <FaArrowRight className="text-xl" />
            </div>
            <h1 className="max-lg:text-3xl max-lg:text-center  lg:text-5xl font-bold text-white">
              What Client’s Say
              <br />
              About Us!
            </h1>
            <div className="border-t-[1px] border-gray-300 my-3 w-[95%]" />
            <div className="w-[100%] mt-5 relative flex flex-col gap-5 ">
            <motion.div
            animate={{
                x: [0, 30, 0],
                transition: {
                    type: 'spring',
                    damping: 10,
                    stiffness: 100,
                    type:'twine',
                    repeatType:'loop',
                    repeat: Infinity,
                    duration:4
    
    
                },
            }}
            className="w-[100%] h-[100%] absolute bottom-0 right-0">
                <img src={require('../../Assets/building-shape.webp')} alt="" />
            </motion.div>
              <Swiper
                navigation={{
                  prevEl: ".custom-prev", // Target custom classes for navigation
                  nextEl: ".custom-next",
                }}
                spaceBetween={30} // Space between slides
                slidesPerView={1} // Show one slide at a time
                loop={true} // Enable looping
                speed={2000} // Slow transition speed in ms
                autoplay={{
                  delay: 3000, // Delay between transitions in ms
                  disableOnInteraction: false, // Continue autoplay even after interaction
                }}
                pagination={{ clickable: true }} // Show pagination dots
                modules={[Autoplay, Navigation, Pagination]}
                className="w-[100%] h-[100%] flex "
              >
                <SwiperSlide>
                  <div className="w-[100%] flex flex-col gap-5">
                    <FaQuoteLeft className="text-[3rem] text-[#ff533c]" />
                    <p className="text-2xl w-[90%] tracking-wide leading-[2rem]">
                    A.R. Roogi & Co. delivered an outstanding job on the Mudhol road widening and improvement project. The transformation has been remarkable, significantly easing traffic congestion and enhancing connectivity across the city. Their expertise and commitment to quality were evident throughout the entire process, and the results speak for themselves.
                    </p>
                    <h1 className="text-2xl text-orange-500 font-bold">
                    Bagalkot District Authority
                    </h1>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-[100%] flex flex-col gap-5">
                    <FaQuoteLeft className="text-[3rem] text-[#ff533c]" />
                    <p className="text-2xl w-[90%] tracking-wide leading-[2rem]">
                    The resurfacing of the Raichur Bachi SH-20 Road by A.R. Roogi & Co. was executed with remarkable precision and efficiency. The newly improved surface has enhanced the driving experience, reducing travel time and increasing safety. We appreciate their attention to detail and adherence to project timelines.
                    </p>
                    <h1 className="text-2xl text-orange-500 font-bold">
                    Construction Manager, Highway Projects
                    </h1>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-[100%] flex flex-col gap-5">
                    <FaQuoteLeft className="text-[3rem] text-[#ff533c]" />
                    <p className="text-2xl w-[90%] tracking-wide leading-[2rem]">
                    The resurfacing of the Raichur Bachi SH-20 Road by A.R. Roogi & Co. was executed with remarkable precision and efficiency. The newly improved surface has enhanced the driving experience, reducing travel time and increasing safety. We appreciate their attention to detail and adherence to project timelines.
                    </p>
                    <h1 className="text-2xl text-orange-500 font-bold">
                     Bagalkot Taluk Official
                    </h1>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="w-[100%] flex flex-col gap-5">
                    <FaQuoteLeft className="text-[3rem] text-[#ff533c]" />
                    <p className="text-2xl w-[90%] tracking-wide leading-[2rem]">
                    A.R. Roogi & Co. has been instrumental in the successful upgrades to the Ramdurga-Manvi SH14 and Hungunda-Paltti Cross MDR. Their work on these stretches has significantly improved road conditions, providing smoother, safer travel for residents and commuters. We commend their dedication to quality and professionalism in delivering these crucial infrastructure projects.
                    </p>
                    <h1 className="text-2xl text-orange-500 font-bold">
                    Infrastructure Development Board
                    </h1>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="md:flex max-md:flex max-md:flex-col-reverse gap-5 mt-5 w-[90%] justify-between items-center">
                <div className="flex  gap-3 items-center">
                  <img
                    src={require("../../Assets/client-1.webp")}
                    alt="testinomial"
                  />
                  <img
                    src={require("../../Assets/client-1.webp")}
                    alt="testinomial"
                  />
                  <img
                    src={require("../../Assets/client-1.webp")}
                    alt="testinomial"
                  />
                </div>
                <div className="flex gap-5 items-center text-2xl">
                  <FaArrowLeftLong className="custom-prev" />
                  <FaArrowRight className="text-[#ff533c] custom-next" />
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-[45%] max-lg:hidden">
                <img src={require('../../Assets/testimonial-image.webp')} alt="test" className="w-[100%] h-[100%] object-cover"/>
          </div>
        </div>
      </div>
    </section>
  );
}
