import React from "react";
import {
  FaArrowLeft,
  FaArrowRight,
  FaAward,
  FaRegHandshake,
  FaUserFriends,
} from "react-icons/fa";
import { GiGiftOfKnowledge } from "react-icons/gi";
export default function Achivments() {
  return (
    <section className="flex w-[100vw] items-center justify-center overflow-hidden">
      <div className="w-[100%] px-[7%] mt-[3rem]  max-w-[1400px] flex items-center justify-center">
        <div
          style={{ zIndex: 1 }}
          className="w-[100%] flex gap-5 py-[7rem] items-center bg-slate-100 px-[3rem] relative"
        >
          <div
            style={{ zIndex: -1 }}
            className="absolute text-white w-[62%]  left-0 h-[100%] top-0  items-center"
          >
            <img
              src={require("../../Assets/achivements-shape.webp")}
              className="w-[100%] object-cover"
              alt="achivments"
            />
          </div>
          <div className="w-[50%] ab flex text-white flex-col gap-10">
            <div className="flex w-[100%] items-center gap-3 ">
              <FaArrowLeft className="text-xl" />{" "}
              <h1 className="text-lg font-semibold uppercase tracking-wider">
              A R Roogi & Co   
              </h1>
              <FaArrowRight className="text-xl" />
            </div>
            <h1 className="text-5xl font-bold">
              Industrial Power, Worldwide Influence
            </h1>
            <a
              className="theme-btn  uppercase flex gap-2 w-fit bg-white h-fit hover:text-white text-black wow fadeInUp"
              data-wow-delay=".3s"
              href="/contact"
              style={{ visibility: "visible" }}
            >
             Make an Appointment
              <FaArrowRight />
            </a>
          </div>
          <div className="w-[50%] grid grid-cols-2 gap-28 justify-around items-center">
            <div className="w-[100%] flex  gap-4 items-center justify-center">
              <div className="bg-white  text-[2rem] text-orange-500 flex items-center justify-center w-[70px] h-[70px] ">
                <FaRegHandshake />
              </div>
              <div className=" w-[70%] flex flex-col ">
                <h1 className="text-5xl font-bold text-[#252525]">40+</h1>
                <span className="text-gray-500 font-semibold">
                  project Complete
                </span>
              </div>
            </div>
            <div className="w-[100%] flex  gap-4 items-center justify-center">
              <div className="bg-white  text-[2rem] text-orange-500 flex items-center justify-center w-[70px] h-[70px] ">
                <FaUserFriends />
              </div>
              <div className=" w-[70%] flex flex-col ">
                <h1 className="text-5xl font-bold text-[#252525]">25+</h1>
                <span className="text-gray-500 font-semibold">
                  Active On Clients
                </span>
              </div>
            </div>
            <div className="w-[100%] flex  gap-4 items-center justify-center">
              <div className="bg-white  text-[2rem] text-orange-500 flex items-center justify-center w-[70px] h-[70px] ">
                <GiGiftOfKnowledge />
              </div>
              <div className=" w-[70%] flex flex-col ">
                <h1 className="text-5xl font-bold text-[#252525]">50+</h1>
                <span className="text-gray-500 font-semibold">
                  Experience Team
                </span>
              </div>
            </div>
            <div className="w-[100%] flex  gap-4 items-center justify-center">
              <div className="bg-white  text-[2rem] text-orange-500 flex items-center justify-center w-[70px] h-[70px] ">
                <FaAward />
              </div>
              <div className=" w-[70%] flex flex-col ">
                <h1 className="text-5xl font-bold text-[#252525]">15+</h1>
                <span className="text-gray-500 font-semibold">
                years of experience
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
