import React from "react";

export default function Map() {
  return (
    <section className="w-[100%] flex items-center justify-center">
      <div className="max-w-[1400px] w-[100%] h-[30rem]">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15558.73611932165!2d77.5682685!3d12.8636732!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae6aa5fac245ab%3A0xd61201f4d169d413!2sVera%20Narayanamma%20Temple!5e0!3m2!1sen!2sin!4v1731063975276!5m2!1sen!2sin"
            className="w-[100%] h-[100%]"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </section>
  );
}
