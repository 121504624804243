import React from 'react'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { MdOutlinePhoneInTalk } from 'react-icons/md'

export default function Contact() {
  return (
    <section className="w-[100vw] flex items-center bg5 justify-center overflow-hidden">
      <div className="w-[100%] max-w-[1400px] bg-[#012f53ad] flex md:px-[7%] max-md:px-[3%] max-md:py-[3rem] md:py-[7rem] justify-center items-center">
        <div className="w-[100%] lg:flex max-lg:flex max-lg:flex-col-reverse  gap-14 items-center">
            <div className="md:w-[50%] bg-white flex flex-col gap-5 p-[1.5rem]">
                <h1 className='font-bold text-2xl '>
                Get in touch!
                </h1>
                <form action="" className='w-[100%] flex flex-col gap-5'>
                     <div className='w-[1005] flex gap-4 items-center'>
                        <input type="text" placeholder='Full Name' name='name' 
                        className='w-[50%] outline-none px-3 py-3 border-[1px] border-[#ee692b] bg-slate-200'
                        />
                        <input type="email" placeholder='Email ' name='email' 
                        className='w-[50%] outline-none px-3 py-3 border-[1px] border-[#ee692b] bg-slate-200'
                        />
                     </div>
                     <div className='w-[1005] flex gap-4 items-center'>
                        <input type="number" placeholder='Phone' name='phone' 
                        className='w-[50%] outline-none px-3 py-3 border-[1px] border-[#ee692b] bg-slate-200'
                        />
                        <input type="text" placeholder='Subject ' name='subject' 
                        className='w-[50%] outline-none px-3 py-3 border-[1px] border-[#ee692b] bg-slate-200'
                        />
                     </div>
                     <textarea rows={6} placeholder='Message'   className='w-[100%] outline-none px-3 py-3 border-[1px] border-[#ee692b] bg-slate-200'>

                     </textarea>
                     <button className="header-button flex gap-2">
                <a className="theme-btn bg-[#f0462f] text-white flex gap-2 items-center" href="/contact">
                  GAT A QUOTE <FaArrowRight />
                </a>
              </button>
                </form>
            </div>
            <div className="md:w-[50%] max-lg:items-center max-lg:justify-center text-white flex flex-col gap-7">
            <div className="flex w-[100%] justify-center items-center gap-3 text-white">
              <FaArrowLeft className="text-xl" />{" "}
              <h1 className="text-lg font-bold uppercase tracking-wider">
              talk to us
              </h1>
              <FaArrowRight className="text-xl" />
            </div>
            <h1 className="md:text-5xl max-md:text-3xl font-bold text-white">
            We are here to assist you
            </h1>
            <p>
            Whether you have a question, need a quote, or want to discuss a new road development, fill out the form below and our team will get back to you promptly. Let’s build the future of infrastructure together!
            </p>
            <div className='flex gap-5 items-center'>
                <div className="w-[4rem] h-[4rem] border-[1px] border-white rounded-full flex items-center justify-center">
                    <div className='w-[3rem] h-[3rem] text-2xl rounded-full bg-white flex items-center justify-center text-black'>
                    <MdOutlinePhoneInTalk />
                    </div>
                </div>
                    <div className='flex flex-col'>
                        <span className='text-sm font-semibold'>
                        Contact Us </span>
                        <p className='text-2xl font-bold '>
                        91 9448688179
                        </p>
                    </div>

            </div>
            </div>
        </div>
    </div>
    </section>
  )
}
