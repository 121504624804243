import React, { useState } from "react";
import Header from "../Home/Header";
import Banner from "../About/Banner";
import Client from "../Home/Client";
import Footer from "../Home/Footer";
import Project from "./Project";
import Address from "../Menu/Address";

export default function ProjectContainer() {
  const [card, setCard] = useState(false);
  return (
    <>
      <Header setCard={setCard} />

      <Address setCard={setCard} card={card} />
      <div className="relative w-[100%] h-[100%]">
        <div
          className={` ${
            card &&
            "w-[100%] absolute left-0  top-0  h-[100%] z-10 bg-[#080808bd]"
          }`}
        />
        <Banner name={"Project"} title={"Project"} />
        <Project />
        <Client />
        <Footer />
      </div>
    </>
  );
}
