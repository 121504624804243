import React from "react";
import { FaArrowRight } from "react-icons/fa";

export default function ContactForm() {
  return (
    <section className="w-[100vw] flex items-center justify-center overflow-hidden">
      <div className="w-[100%] max-w-[1400px] px-[10%] py-[3rem]">
        <div className="w-[100%] shadow-lg border-[1px] flex flex-col items-center justify-center gap-5 border-gray-300 px-[3rem] py-[6rem]">
          <h1 className="text-5xl font-bold tracking-wide">Get In Touch</h1>
          <form className="mt-10 w-[100%] flex flex-col gap-7">
            <div className="w-[100%] flex items-center gap-5">
              <input
                type="text"
                name="name"
                placeholder="Full Name"
                className="outline-none bg-slate-100 w-[50%] px-5 py-4" required
              />
                 <input
                type="text"
                name="name"
                placeholder="Last Name"
                className="outline-none bg-slate-100 w-[50%] px-5 py-4" required
              />
            </div>
            <div className="w-[100%] flex items-center gap-5">
              <input
                type="number"
                name="phone"
                placeholder="Phone Number"
                className="outline-none bg-slate-100 w-[50%] px-5 py-4" required
              />
                 <input
                type="email"
                name="email"
                placeholder="Email Address"
                className="outline-none bg-slate-100 w-[50%] px-5 py-4" required
              />
            </div>
            <textarea rows={7} name="message" placeholder="Message"
                className="outline-none bg-slate-100 w-[100%] px-5 py-4" required>

            </textarea>
            <button className="header-button w-[100%] flex gap-2">
                <p className="theme-btn w-[100%] text-center justify-center bg-[#012F53] text-white flex gap-2 items-center" href="/contact">
                SEND MESSAGE NOW
                </p>
              </button>
          </form>
        </div>
      </div>
    </section>
  );
}
