import React from "react";
import { FaArrowLeft, FaArrowRight, FaCheckCircle } from "react-icons/fa";
import { HiPlay } from "react-icons/hi";
import { IoIosPlay } from "react-icons/io";

export default function About({setVideo}) {
  return (
    <section className="w-[100vw] flex items-center justify-center overflow-hidden">
      <div className="w-[100%] relative max-w-[1400px] flex max-md:px-[3%] md:px-[10%] max-md:py-[3rem] md:py-[7rem] justify-center items-center">
        
        <div className="w-[100%] lg:flex max-lg:flex max-lg:flex-col-reverse  gap-4 h-[100%]">
          <div className="lg:w-[45%] h-[100%] relative">
            <div className="md:w-[50%] max-md:w-[60%] absolute bottom-0 max-md:h-[10rem] md:h-[17rem] flex items-center justify-center right-0 overflow-hidden border-[5px] border-white rounded-sm">
              <img
                src={require("../../Assets/2.jpeg")}
                className="w-[100%] h-[100%] object-cover overflow-hidden"
                alt="about"
              />
              <div className="w-[5rem] h-[5rem] flex items-center justify-center absolute border-[1px] border-[#012F53]">
                <div onClick={()=>setVideo(true)} className="w-[4rem] flex items-center justify-center text-white text-5xl h-[4rem] bg-[#012F53]">
                  <IoIosPlay />
                </div>
              </div>
            </div>
            <img
              src={require("../AR/About company image/about company .jpg")}
              className="w-[100%] h-[35rem] object-cover"
              alt="image"
            />
          </div>
          <div className=" h-[100%]">
            <img
              src={require("../../Assets/about-shape-3.webp")}
              className="h-[100%] max-md:hidden"
              alt=""
            />
          </div>
          <div className="lg:w-[50%] flex flex-col gap-5">
            {/* <div className="flex w-[100%] items-center gap-3 text-[#993D30]">
              <FaArrowLeft className="text-xl" />{" "}
              <h1 className="text-xl font-semibold uppercase tracking-wider">
                1976 Building company
              </h1>
              <FaArrowRight className="text-xl" />
            </div> */}
            <h1 className="max-md:text-4xl md:text-5xl  font-bold">
            Build Your Vision with Us
            </h1>
            <p className="leading-[1.7rem] mt-5">
         
            At M/s. A.R. Roogi & Co, we specialize in delivering comprehensive civil engineering and construction services for public sector projects. Our expertise spans construction, project management, and consulting, all driven by a commitment to innovation, excellence, and sustainable development.
            </p>
            <div className="mt-4 flex flex-col gap-3">
              <div className="text-md tracking-wider flex items-center gap-4">
                <FaCheckCircle className="text-[#0B3D60] text-2xl" />{" "}
                <h1>Dedicated to enhancing public infrastructure with quality workmanship</h1>
              </div>
              <div className="text-md tracking-wider flex items-center gap-4">
                <FaCheckCircle className="text-[#0B3D60] text-2xl" />{" "}
                <h1>Trusted partner for government agencies and public institutions</h1>
              </div>
              <div className="text-md tracking-wider flex items-center gap-4">
                <FaCheckCircle className="text-[#0B3D60] text-2xl" />{" "}
                <h1>Delivering projects that stand the test of time with integrity and precision</h1>
              </div>
            </div>
            <div className="border-t-[1px] border-gray-300 w-[100%] my-4"/>
            <div className="w-[100%] flex items-center gap-24">
                <div className="md:flex max-md:flex max-md:flex-col max-md:items-center max-md:justify-center gap-3 items-center">
                    <div className="w-[5rem] h-[5rem] rounded-full overflow-hidden flex items-center justify-center">
                        <img src={require('../../Assets/01 (1).webp')} className="w-[100%] object-cover" alt="about" />
                    </div>
                        <div className="flex flex-col gap-0">
                            <h1 className="text-sm text-gray-500 max-md:text-center font-semibold">A R Roogi Constructions, CEO  </h1>
                            <span className="text-2xl font-bold tracking-wider">
                           Appanna Rangappa Roogi
                            </span>
                        </div>

                </div>
                <img src={require('../../Assets/signature.webp')} className="w-[6rem] max-md:hidden" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
