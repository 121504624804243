import { motion } from 'framer-motion'
import React, { useState } from 'react'
import {FaFacebookF, FaLinkedinIn, FaRedditAlien } from 'react-icons/fa'
import { FaXTwitter } from 'react-icons/fa6'
export default function Member({name,position}) {
    const [card,setCard]= useState(false)
  return (
    <a href="/team/1">

    <div onMouseEnter={() => setCard(true)}
    onMouseLeave={() => setCard(false)} className='w-[100%]  h-[100%] flex flex-col gap-5'>
          <motion.div
          animate={{
              rotate:card?10:0,
              
              transition:{duration:0.5}
          }}
          className={`border-[1.2rem]  relative h-[27rem] ${ card?'border-[#ff523c]':'border-[#fad3c4]'} overflow-hidden`}>
              <img src={require('../../Assets/01 (4).webp')} className='w-[100%] h-[100%] object-cover' alt="" />
              <motion.div
              animate={{
                  opacity:card?1:0,
                 y:card?-10:0,
                  transition:{duration:0.5}
              }}
              className="flex w-[100%] items-center justify-center absolute bottom-[5%] left-0">
                  <div className="flex w-[95%] items-center justify-center gap-3 ">
                      <div className='w-[3rem] h-[3rem] text-black rounded-full bg-white flex items-center justify-center'>
                      <FaLinkedinIn />
                      </div>
                      <div className='w-[3rem] h-[3rem] text-black rounded-full bg-white flex items-center justify-center'>
                      <FaFacebookF  />
                      </div>
                      <div className='w-[3rem] h-[3rem] text-black rounded-full bg-white flex items-center justify-center'>
                      <FaXTwitter  />
                      </div>
                      <div className='w-[3rem] h-[3rem] text-black rounded-full bg-white flex items-center justify-center'>
                      <FaRedditAlien />
                      </div>
                  </div>
              </motion.div>
          </motion.div>
          <motion.div
          animate={{
              opacity:card?1:0,
             
              transition:{duration:0.5}
          }}
          className={` flex h-[5rem] flex-col duration-500 px-3`}>
              <p className="text-md text-[#ff523c] ">
                {position}
              </p>
              <h1 className="text-2xl font-bold ">{name}</h1>
          </motion.div>

      </div>
    </a>

  )
}
